<template>
	<div>
		<meta name="viewport" content="width=device-width, initial-scale=1.0">
		<div :style="topScaleRatio">
			<div class="row" style="justify-content: center;">
				<div class="bg">
					<img class="SDlogo" src="@/assets/infyn.png" />
					<div style="margin-top: -61px;">
						<span style="font-size: 24px; font-weight: 600; color: white; text-size-adjust: 50%;">
							You are one step away for booking the <span style="background-color: #f86716;">Hepi Demo Kit</span>
						</span>
					</div>
					<div style="font-size: 19px; color: #8c8c8c; text-size-adjust: 50%;">Demo Kit will be locked-in for you while</div>
					<div style="font-size: 19px; color: #8c8c8c; text-size-adjust: 50%;">you finish your purchase</div>
					<img src="@/assets/setupBoxNew.png" style="position: absolute; z-index: 1; margin-left: -77px; margin-top: -2px; height: 163px;" />
					<center style="margin-top: 20px;">
						<va-card style="width: 780px; background: #0f0f0f; border-radius: 14px; margin-top: 91px;">
							<center style="margin-top: 43px;">
								<div style="font-size: 17px; font-weight: 600; text-size-adjust: 50%;">
									<span style="color: white;">Special Price: <span style="color: #f8741a;">10,000</span></span>
								</div>
								<div style="font-size: 15px; margin-top: 6px; text-size-adjust: 50%;">
									<span style="color: white;">Normal Price: 16,000</span>
								</div>
								<div>
									<img v-if="shipping" class="left_line" src="@/assets/popup_line.png" />
									<img v-if="!shipping" class="right_line" src="@/assets/popup_line.png" />
									<va-card :style="card_css">
										<img src="@/assets/line.png" style="position: absolute; height: 33px; width: 1px;" />
										<div v-if="shipping">
											<div>
												<img src="@/assets/number1C.png" style="position: absolute; height: 38px; top: 14px; left: 64px;" />
												<img src="@/assets/number2.png" style="position: absolute; height: 38px; top: 14px; left: 376px;" />
												<span 
													class="left_head_tag" 
													style="top: 10px; font-size: 17px; color: #f77319; cursor: pointer; text-size-adjust: 50%;">
													Shipping Details
												</span>
												<span
													class="left_head_tag"
													style="top: 33px; font-size: 14px; color: #8c8c8c; cursor: pointer; text-size-adjust: 50%;">
													Fill your details
												</span>
												<span 
													class="right_head_tag"
													style="top: 10px; font-size: 17px; color: white; cursor: pointer; text-size-adjust: 50%;"
													@click="changeView('shipping')">
													Payment Details
												</span>
												<span
													class="right_head_tag"
													style="top: 33px; font-size: 14px; color: #8c8c8c; cursor: pointer; text-size-adjust: 50%;"
													@click="changeView('shipping')">
													Detailed Price
												</span>
											</div>
											<div style="margin-top: 63px;">
												<img class="label_tag" src="@/assets/belowLabel.png" style="width: 58px;" />
												<span class="input_name">Name<a style="color: red; margin-top: 2px;">*</a></span>
												<input class="sh_input" v-model="name" :required="nameValid" />
											</div>
											<div class="mt-4">
												<img class="label_tag" src="@/assets/belowLabel.png" style="width: 55px;" />
												<span class="input_name">Email<a style="color: red; margin-top: 2px;">*</a></span>
												<input class="sh_input" v-model="email" :required="emailValid" />
											</div>
											<div class="mt-4">
												<img class="label_tag" src="@/assets/belowLabel.png" style="width: 94px;" />
												<span class="input_name">Hotel Name<a style="color: red; margin-top: 2px;">*</a></span>
												<input class="sh_input" v-model="hotel_name" :required="hotelNameValid" />
											</div>
											<div class="mt-4">
												<img class="label_tag" src="@/assets/belowLabel.png" style="width: 118px;" />
												<span class="input_name">Phone Number<a style="color: red; margin-top: 2px;">*</a></span>
												<input 
													class="sh_input"
													maxlength="10" 
	                    		oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
													v-model="phone_number" 
													:required="phoneNoValid" 
												/>
											</div>
											<div class="mt-4">
												<img class="label_tag" src="@/assets/belowLabel.png" style="width: 58px;" />
												<span class="input_name">GST No</span>
												<input class="sh_input" v-model="gst" />
											</div>
											<div class="mt-4">
												<img class="label_tag" src="@/assets/belowLabel.png" style="width: 68px;" />
												<span class="input_name">Address<a style="color: red; margin-top: 2px;">*</a></span>
												<input class="sh_input" v-model="address" :required="addressValid" />
											</div>
										</div>

										<div v-if="!shipping">
											<div>
												<img src="@/assets/number1.png" style="position: absolute; height: 38px; top: 14px; left: 64px;" />
												<img src="@/assets/number2C.png" style="position: absolute; height: 38px; top: 14px; left: 376px;" />
												<span 
													class="left_head_tag" 
													style="top: 10px; font-size: 17px; color: white; cursor: pointer; text-size-adjust: 50%;"
													@click="changeView('payment')">
													Shipping Details
												</span>
												<span
													class="left_head_tag"
													style="top: 33px; font-size: 14px; color: #8c8c8c; cursor: pointer; text-size-adjust: 50%;"
													@click="changeView('payment')">
													Fill your details
												</span>
												<span 
													class="right_head_tag"
													style="top: 10px; font-size: 17px; color: #f77319; cursor: pointer; text-size-adjust: 50%;">
													Payment Details
												</span>
												<span
													class="right_head_tag"
													style="top: 33px; font-size: 14px; color: #8c8c8c; cursor: pointer; text-size-adjust: 50%;">
													Detailed Price
												</span>
											</div>
											<img src="@/assets/paymentTag.png" style="position: absolute; width: 124px; left: 111px; top: 87px;" />
											<div style="margin-top: 63px;">
												<div>
													<input 
														name="payment_method" 
														value="Pay Full"
														class="radio_css"
														style="left: 111px;" 
														@click="payment('full')"
														checked="checked"
														type="radio">
	    										<label 
	    										  style="position: absolute; left: 138px; top: 112px; color: white;"
	    										>Pay Full</label>
													<span style="color: white; position: absolute; left: 139px; top: 138px;"><i class="fa fa-rupee"/> 10,000</span>
												</div>
												<div>
		  										<input 
		  											name="payment_method" 
		  											@click="payment('advanced')"
														style="left: 425px;"
		  											class="radio_css" 
		  											value="Pay Advance" 
		  											type="radio">
	    										<label 
	    										  style="position: absolute; left: 452px; top: 112px; color: white;"
	    										>Pay Advance</label> 
													<span style="color: white; position: absolute; left: 453px; top: 138px;"><i class="fa fa-rupee"/> 1,000</span>
												</div>
											</div>
											<div style="margin-top: 120px;">
												<span style="position: absolute; left: 112px; top: 187px; font-size: 16px; color: white; text-size-adjust: 50%;">
													Demo Kit
												</span>
												<span style="position: absolute; top: 187px; font-size: 16px; left: 448px; color: white; text-size-adjust: 50%;">
													Sub Total
												</span>
											</div>
											<div><img src="@/assets/paymentLine.png" style="width: 424px; position: absolute; left: 102px; top: 223px;" /></div>
											<div v-if="full_pay">
												<span style="position: absolute; left: 106px; top: 245px; color: #7e7e7e;">Hepi Demo Kit</span>
												<span style="position: absolute; left: 454px; top: 245px; color: #7e7e7e;"><i class="fa fa-rupee"/>10,000</span>
												<img src="@/assets/paymentLine.png" style="width: 424px; position: absolute; left: 102px; top: 293px;" />
												<span style="position: absolute; top: 318px; left: 112px; color: white;">Total Payable</span>
												<span style="position: absolute; top: 318px; left: 454px; color: white;"><i class="fa fa-rupee"/>10,000</span>
											</div> 
											<div v-if="!full_pay">
												<span style="position: absolute; left: 106px; top: 233px; color: #7e7e7e;">Hepi Demo Kit</span>
												<span style="position: absolute; left: 106px; top: 256px; color: #7e7e7e;">(Advanced)</span>
												<span style="position: absolute; left: 454px; top: 245px; color: #7e7e7e;"><i class="fa fa-rupee"/> 1,000</span>
												<input type="checkbox" v-model="required_amount" style="position: absolute; top: 303px; left: 105px;" />
												<img v-if="requiredFlag" src="@/assets/paymentRequired.png" style="position: absolute; width: 66px; top: 284px; left: 176px;" />
												<label style="position: absolute; top: 290px; left: 129px; font-size: 16px; color: white; text-size-adjust: 50%;">Note<a style="color: red; margin-top: 2px;">*</a></label>
												<label style="position: absolute; top: 309px; left: 129px; font-size: 12px; color: white; text-size-adjust: 50%;">Amount to be pay on delivery</label>
												<span style="position: absolute; left: 454px; top: 296px; color: white;"><i class="fa fa-rupee"/> 9,000</span>
												<img src="@/assets/paymentLine.png" style="width: 424px; position: absolute; left: 102px; top: 337px;" />
												<span style="position: absolute; top: 349px; left: 112px; color: white;">Total Payable</span>
												<span style="position: absolute; top: 349px; left: 454px; color: white;"><i class="fa fa-rupee"/> 1,000</span>
											</div>
										</div>								
									</va-card>
									<div>
										<button 
											v-if="shipping" 
											class="process_button"
											style="background: #343434; border: #343434;" 
											@click="backPage()">
											<img src="@/assets/leftarrow.png" height="12" style="position: absolute; margin-left: -20px; margin-top: 4px;" />Back to Menu
										</button>
										<button 
											v-if="shipping" 
											class="process_button ml-4" 
											@click="nextPage()">Proceed to Payment
											<img src="@/assets/rightarrow.png" height="12" style="position: absolute; margin-left: 6px; margin-top: 4px;" />
										</button>
									</div>
									<button 
										v-if="!shipping" 
										class="process_button" @click="razorPay()">
											<span v-if="!withoutResponsing"><img height="12" src="@/assets/lock.png"/></span> 
											<span v-if="withoutResponsing" style="position: absolute; margin-left: -20px;">
												<i class="fa fa-spinner fa-spin" />
											</span> 
											Pay Now
									</button>
								</div>
							</center>
						</va-card>
					</center>	
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
  import vueResource from 'vue-resource';
  import config from '../../i18n/en.json';
  import ScreenSizeDetector from 'screen-size-detector';
  Vue.use(vueResource)

  export default {
    created () {
    	var screenSize=new ScreenSizeDetector().width;
      var scaleRatioNumber=screenSize<600 ? (0.00125*screenSize) : 1;
      this.topScaleRatio="zoom: "+scaleRatioNumber+";";
      this.bottomScaleRatio="zoom: "+(scaleRatioNumber!=1 ? scaleRatioNumber+0.2 : scaleRatioNumber)+";";
    	this.getPayment();
    	if(this.$cookies.get('x-access-token')) this.fetchUser();
    },
    data () {
    	return {
    		shipping: true,
    		name: '',
    		email: '',
    		phone_number: '',
    		hotel_name: '',
    		address: '',
    		gst: '',
    		nameValid: false,
    		emailValid: false,
    		hotelNameValid: false,
    		phoneNoValid: false,
    		addressValid: false,
    		card_css: 'width: 629px; margin-top: 15px; background: #232222;',
    		full_payment: true,
    		advance_payment: false,
    		full_pay: true,
    		required_amount: false,
    		requiredFlag: false,
    		withoutResponsing: false,
    		topScaleRatio: '',
    		bottomScaleRatio: ''
    	}
    },
    methods: {
    	fetchUser() {
    		this.$http.get(config.menu.host + 'content/user').then(user => {
    			this.name = user.body[0].full_name;
    			this.email = user.body[0].email;
    			this.phone_number = user.body[0].mobile;
    			this.address = user.body[0].address;
    			this.$http.get(config.menu.host + 'content/integration').then(response => {
    				this.hotel_name = response.body[0].hotel_name;
    			})
    		})
    	},
    	getPayment() {
    		function QueryStringToJSON () {
          var pairs = location.search.slice(1).split('&')
          var result = {}
          pairs.forEach(function (pair) {
            pair = pair.split('=')
            result[pair[0]] = decodeURIComponent(pair[1] || '')
          })
          return JSON.parse(JSON.stringify(result))
        }
        var query_string = QueryStringToJSON()

        if (Object.keys(query_string).length > 1) {
					this.$http.put(config.menu.host + 'content/razorpay/payment' , { query : query_string }).then(response => {
						this.$router.push({ 
							name: 'notification', 
							params: { 
								'razorpay_status' : 'success', 
								'reference_number' : response.body.reference_number 
							} 
						});
						if(response.body.token) Vue.$cookies.set('x-access-token', response.body.token);
					}, err => {
						this.$router.push({ name: 'notification', params: { 'razorpay_status' : 'fail' } });
					})        	
        }
    	},
    	payment(type) {
    		if(type == 'full') this.full_pay = true;
    		if(type == 'advanced') this.full_pay = false;
    	},
    	changeView (type) {
    		if(type == 'shipping') return this.nextPage();
    		if(type == 'payment') {
    			this.card_css = 'width: 629px; margin-top: 15px; background: #232222;';
    			return this.shipping = true;
    		}
    	},
    	nextPage () {
    		this.resetForm();
    		if(!this.name) return this.nameValid = true;
    		if(!this.email) return this.emailValid = true;
    		if(!this.hotel_name) return this.hotelNameValid = true;
    		if(!this.phone_number) return this.phoneNoValid = true;
    		if(!this.address) return this.addressValid = true;

    		this.shipping = false;
    		this.requiredFlag = false;
  			this.card_css = 'width: 629px; margin-top: 15px; background: #232222; height: 400px;';
    	},
    	backPage() {
    		this.$router.push({ name: 'dashboard' });
    	},
    	razorPay() {
    		if(!this.full_pay && !this.required_amount) return this.requiredFlag = true;
    		this.withoutResponsing = true;
    		var payload = {
    			'name' : this.name,
    			'amount' : (this.full_pay) ? '10000' : '1000',
    			'phone_number' : this.phone_number,
    			'email' : this.email,
    			'address' : this.address,
    			'hotel_name' : this.hotel_name,
    			'redirect_url' : location.host+'/shipping'
    		}

    		this.$http.post(config.menu.host + 'content/razorpay/payment', payload).then(response => {
    			if(response.body) {
    				if(response.body.url) window.open(response.body.url, '_self')
    			};
    		});
    	},
    	resetForm () {
    		this.nameValid = false;
    		this.emailValid = false;
    		this.hotelNameValid = false;
    		this.phoneNoValid = false;
    		this.addressValid = false;
    	}
    }
  }
</script>

<style>
  body {
    background-image: url("../../assets/new_repeat.jpg");
    background-position: left top;
    background-repeat: repeat;
    background-size: 10px;
  }

  button {
    cursor: pointer;
  }

  input:required {
    border: red solid 1px;
  }

  .bg {
    background-image: url(/img/circlebg.52d2ead0.png);
    position: relative;
    background-repeat: no-repeat;
    background-size: 742px;
    text-align: center;
    height: 670px;
    width: 947px;
    background-position: center;
  }

  .SDlogo {
    height: 100px;
    width: 100px;
    margin-left: -1071px;
  }

  .left_line {
    width: 185px;
    position: absolute;
    margin-left: -254px;
    margin-top: -1px;
    height: 5px;
  }

  .right_line {
    width: 185px;
    position: absolute;
    margin-left: 53px;
    margin-top: -1px;
    height: 5px;
  }

  .left_head_tag {
    position: absolute;
    left: 111px;
  }

  .right_head_tag {
    position: absolute;
    left: 423px;
  }

  .sh_input {
    background-color: #232222;
    border: 1px solid #3f3f3f;
    border-radius: 8px;
    height: 50px;
    width: 568px;
    text-indent: 28px;
    color: white;
    font-size: 13px;
    text-size-adjust: 50%;
  }

  .label_tag {
    width: 78px;
    position: absolute;
    margin-top: -7px;
    margin-left: 22px;
  }

  .input_name {
    position: absolute;
    margin-top: -13px;
    margin-left: 27px;
    color: #5e5e5e;
  }

  .process_button {
    height: 39px;
    width: 203px;
    margin-top: 21px;
    color: white;
    background: green;
    border: green;
    border-radius: 4px;
    text-size-adjust: 50%;
  }

  .radio_css {
    position: absolute;
    left: 111px;
    top: 129px;
  }
</style>
